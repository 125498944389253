import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, doc, updateDoc, getDoc, deleteDoc} from 'firebase/firestore';
import { db } from '../firebase'; // Firestore configuration
import Sidebar from './Sidebar'; // Import Sidebar component
import '../styles/UserManagementPage.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
// Import CSS for styling
import Modal from 'react-modal'; // Import react-modal for popup functionality

// Set the app element for accessibility reasons
Modal.setAppElement('#root');

const UserManagementPage = () => {
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [users, setUsers] = useState([]); // State for all users
  const [filteredUsers, setFilteredUsers] = useState([]); // State for filtered users
  const [selectedUser, setSelectedUser] = useState(null); // State for selected user
  const [totalUsers, setTotalUsers] = useState(0); // Total number of users
  const [premiumUsers, setPremiumUsers] = useState(0); // Number of premium users
  const [filter, setFilter] = useState('all'); // State to track the filter (all or premium)
  const [animalAds, setAnimalAds] = useState([]); // State to store animal ads for the selected user

  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState(false); // Modal state
  const [premiumDays, setPremiumDays] = useState(30);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete confirmation modal
  const [animalToDelete, setAnimalToDelete] = useState(null);
  const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState(false); // State for user delete confirmation modal
  const [userToDelete, setUserToDelete] = useState(null); // Store the user to be deleted


  // Function to fetch users from Firestore
  const fetchUsers = async () => {
    const usersCollection = collection(db, 'users');
    const userSnapshot = await getDocs(usersCollection);
    const userList = userSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    // Count total users and premium users
    const total = userList.length;
    const premium = userList.filter((user) => user.premium === true).length;

    setUsers(userList);
    setFilteredUsers(userList); // Initialize the filtered users with all users
    setTotalUsers(total); // Set total users
    setPremiumUsers(premium); // Set premium users count
  };

  // Fetch users from Firestore on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  // Filter users based on selected filter and search query
  useEffect(() => {
    const filtered = users.filter((user) => {
      const matchesSearch = user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.email.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesFilter = filter === 'all' || (filter === 'premium' && user.premium === true);
      return matchesSearch && matchesFilter;
    });
    setFilteredUsers(filtered);
  }, [searchQuery, filter, users]);

  // Fetch animal ads for the selected user
  useEffect(() => {
    const fetchAnimalAds = async (uid) => {
      const animalCollection = collection(db, 'animals');
      const animalQuery = query(animalCollection, where('uid', '==', uid));
      const animalSnapshot = await getDocs(animalQuery);
      const animalList = animalSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      setAnimalAds(animalList);
    };

    if (selectedUser?.id) {
      fetchAnimalAds(selectedUser.id);
    } else {
      setAnimalAds([]); // Reset animal ads if no user is selected
    }
  }, [selectedUser]);

  // Toggle Premium - Open Modal
  const openPremiumModal = () => {
    setIsPremiumModalOpen(true);
  };

  const handleDeleteAnimal = async () => {
    if (animalToDelete) {
      try {
        const animalRef = doc(db, 'animals', animalToDelete.id);
        await deleteDoc(animalRef); // Delete the animal document
        setAnimalAds((prevAds) => prevAds.filter((ad) => ad.id !== animalToDelete.id)); // Remove from UI
        closeDeleteModal(); // Close the confirmation modal
      } catch (error) {
        console.error('Error deleting animal ad:', error);
      }
    }
  };



  // Close Premium Modal
  const closePremiumModal = () => {
    setIsPremiumModalOpen(false);
  };

  // Handle Premium Selection (Set Days)
  const handlePremiumSelection = (days) => {
    setPremiumDays(days);
  };

  // Fetch updated user data after premium update
  const refreshSelectedUser = async (userId) => {
    const userRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userRef);
    const updatedUser = { id: userSnapshot.id, ...userSnapshot.data() };
    setSelectedUser(updatedUser); // Update the selected user state with fresh data
  };

  // Handle Submission of Premium Status
  const handlePremiumSubmit = async () => {
    if (selectedUser && premiumDays > 0) {
      const userRef = doc(db, 'users', selectedUser.id);
      const buyDate = new Date(); // Get today's date
      const validity = new Date(buyDate);
      validity.setDate(buyDate.getDate() + premiumDays); // Add premiumDays to the current date

      // Update user data in Firestore
      await updateDoc(userRef, {
        premium: true,
        premiumBuyDate: buyDate,
        premiumValidity: validity,
      });

      // Close modal and refresh user info
      closePremiumModal();
      await refreshSelectedUser(selectedUser.id); // Fetch the updated data for the selected user

      // Refresh user list after making changes
      await fetchUsers();
    }
  };

  // Handle Cancel Premium
  const handleCancelPremium = async () => {
    if (selectedUser) {
      const userRef = doc(db, 'users', selectedUser.id);

      // Update user data in Firestore to cancel premium status
      await updateDoc(userRef, {
        premium: false,
        premiumBuyDate: null,
        premiumValidity: null,
      });

      // Refresh selected user data
      await refreshSelectedUser(selectedUser.id); // Fetch the updated data for the selected user

      // Refresh user list after making changes
      await fetchUsers();
    }
  };


  // Open delete confirmation modal
  const openDeleteModal = (animal) => {
    setAnimalToDelete(animal);
    setIsDeleteModalOpen(true);
  };

  // Close delete confirmation modal
  const closeDeleteModal = () => {
    setAnimalToDelete(null);
    setIsDeleteModalOpen(false);
  };

  // Open user delete confirmation modal
  const openUserDeleteModal = (user) => {
    setUserToDelete(user);
    setIsUserDeleteModalOpen(true);
  };

// Close user delete confirmation modal
  const closeUserDeleteModal = () => {
    setUserToDelete(null);
    setIsUserDeleteModalOpen(false);
  };

// Function to delete the user and their animal ads from Firestore
  // Function to delete the user and their animal ads from Firestore
  const handleDeleteUser = async () => {
    if (userToDelete) {
      try {
        console.log(`Attempting to delete user: ${userToDelete.name} (ID: ${userToDelete.id})`);

        // Query all the animal ads posted by the user
        const animalCollection = collection(db, 'animals');
        const animalQuery = query(animalCollection, where('uid', '==', userToDelete.id));
        const animalSnapshot = await getDocs(animalQuery);

        console.log(`Found ${animalSnapshot.size} animal ads associated with this user.`);

        // Delete each animal ad associated with the user
        const deletePromises = animalSnapshot.docs.map((doc) => {
          console.log(`Deleting animal ad with ID: ${doc.id}`);
          return deleteDoc(doc.ref);
        });
        await Promise.all(deletePromises); // Wait for all animal ads to be deleted

        console.log(`Successfully deleted all animal ads for user: ${userToDelete.name} (ID: ${userToDelete.id})`);

        // After deleting all animal ads, delete the user
        const userRef = doc(db, 'users', userToDelete.id);
        await deleteDoc(userRef);

        console.log(`Successfully deleted user: ${userToDelete.name} (ID: ${userToDelete.id})`);

        // Update the UI to reflect the user and their ads being deleted
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userToDelete.id)); // Remove user from UI
        setAnimalAds([]); // Clear the animal ads list
        closeUserDeleteModal(); // Close the confirmation modal
      } catch (error) {
        console.error(`Error deleting user or their animal ads: ${error.message}`);
      }
    }
  };



  return (
      <div className="dashboard-container">
        <Sidebar activePage="user-management" />

        <div className="user-management-content">
          <div className="user-card-section">
            <header className="user-management-header">
              <h1>User Management</h1>
              <input
                  type="text"
                  placeholder="Search by name or email..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="search-input"
              />
            </header>

            <div className="user-stats-cards">
              <div
                  className={`stat-card ${filter === 'all' ? 'active' : ''}`}
                  onClick={() => setFilter('all')}
              >
                <h3>Total Users</h3>
                <p>{totalUsers}</p>
              </div>
              <div
                  className={`stat-card premium-card ${filter === 'premium' ? 'active' : ''}`}
                  onClick={() => setFilter('premium')}
              >
                <h3>Premium Users</h3>
                <p>{premiumUsers}</p>
              </div>
            </div>

            <div className="user-card-list">
              {filteredUsers.length > 0 ? (
                  filteredUsers.map((user) => (
                      <div
                          className={`user-card ${selectedUser?.id === user.id ? 'selected' : ''}`}
                          key={user.id}
                          onClick={() => setSelectedUser(user)}
                          style={{position: 'relative'}} // Make the user card position relative
                      >
                        <h2>{user.name}</h2>
                        <p>{user.email}</p>

                        {/* Circular Delete User Button */}
                        <button onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the select user function
                          openUserDeleteModal(user);
                        }} className="delete-user-btn">
                          <i className="fas fa-trash"></i> {/* FontAwesome trash icon */}
                        </button>
                      </div>
                  ))
              ) : (
                  <p>No users found</p>
              )}
            </div>


          </div>

          <div className="user-detail-section">
            {selectedUser ? (
                <>
                  <div className="user-detail-card">
                    <h2>{selectedUser.name}</h2>
                    <p>Email: {selectedUser.email}</p>

                    {/* Display Premium Information if available */}
                    {selectedUser.premium && (
                        <div className="premium-info">
                          <p><strong>Premium Buy
                            Date:</strong> {new Date(selectedUser.premiumBuyDate.seconds * 1000).toLocaleDateString()}
                          </p>
                          <p><strong>Premium
                            Validity:</strong> {new Date(selectedUser.premiumValidity.seconds * 1000).toLocaleDateString()}
                          </p>
                        </div>
                    )}

                    {/* Toggle Premium Button */}
                    <button onClick={openPremiumModal} className="toggle-premium-btn">
                      {selectedUser.premium ? "Extend Premium" : "Make Premium"}
                    </button>

                    {/* Cancel Premium Button */}
                    {selectedUser.premium && (
                        <button onClick={handleCancelPremium} className="cancel-premium-btn">
                          Cancel Premium
                        </button>
                    )}
                  </div>
                  <h3>Animal Ads Posted</h3>
                  <div className="animal-ads-section">
                    {animalAds.length > 0 ? (
                        animalAds.map((ad) => (
                            <div key={ad.id} className="animal-ad-card">
                              {/* Delete icon to open the confirmation modal */}
                              <button onClick={() => openDeleteModal(ad)} className="delete-icon-btn">
                                <i className="fas fa-trash"></i> {/* FontAwesome trash icon */}
                              </button>
                              {ad.thumbnail ? (
                                  <img src={ad.thumbnail} alt={ad.animalType} className="animal-thumbnail"/>
                              ) : (
                                  <div className="no-thumbnail">No Image Available</div>
                              )}
                              <div className="animal-info">
                                <h4>{ad.animalType} {ad.breed}</h4>
                                <p>Price: {ad.price}</p>
                                <p>Address: {ad.address}</p>
                                <p>City: {ad.city}</p>
                                <p>Mobile No.: {ad.mobileNumber}</p>
                              </div>
                            </div>
                        ))
                    ) : (
                        <p>No animal ads posted by this user.</p>
                    )}
                  </div>


                  {/* Modal for Premium Days Selection */}
                  <Modal
                      isOpen={isPremiumModalOpen}
                      onRequestClose={closePremiumModal}
                      contentLabel="Select Premium Days"
                      className="premium-modal"
                      overlayClassName="premium-modal-overlay"
                  >
                    <h2>Select Premium Duration</h2>
                    <div className="premium-options">
                      <button onClick={() => handlePremiumSelection(30)}
                              className={premiumDays === 30 ? "active" : ""}>30 Days
                      </button>
                      <button onClick={() => handlePremiumSelection(90)}
                              className={premiumDays === 90 ? "active" : ""}>90 Days
                      </button>
                      <button onClick={() => handlePremiumSelection(180)}
                              className={premiumDays === 180 ? "active" : ""}>180 Days
                      </button>
                      <button onClick={() => handlePremiumSelection(365)}
                              className={premiumDays === 365 ? "active" : ""}>365 Days
                      </button>
                    </div>
                    <button onClick={handlePremiumSubmit} className="submit-btn">Submit</button>
                    <button onClick={closePremiumModal} className="cancel-btn">Cancel</button>
                  </Modal>

                  {/* Delete Confirmation Modal */}
                  <Modal
                      isOpen={isDeleteModalOpen}
                      onRequestClose={closeDeleteModal}
                      contentLabel="Confirm Delete Post"
                      className="delete-modal"
                      overlayClassName="delete-modal-overlay"
                  >
                    <h2>Are you sure you want to delete this animal ad?</h2>
                    <div className="modal-actions">
                      <button onClick={handleDeleteAnimal} className="confirm-delete-btn">Confirm</button>
                      <button onClick={closeDeleteModal} className="cancel-delete-btn">Cancel</button>
                    </div>
                  </Modal>
                  {/* Delete User Confirmation Modal */}
                  <Modal
                      isOpen={isUserDeleteModalOpen}
                      onRequestClose={closeUserDeleteModal}
                      contentLabel="Confirm Delete User"
                      className="delete-modal"
                      overlayClassName="delete-modal-overlay"
                  >
                    <h2>Are you sure you want to delete this user?</h2>
                    <div className="modal-actions">
                      <button onClick={handleDeleteUser} className="confirm-delete-btn">Confirm</button>
                      <button onClick={closeUserDeleteModal} className="cancel-delete-btn">Cancel</button>
                    </div>
                  </Modal>

                </>
            ) : (
                <div className="user-placeholder">
                  <p>Select a user to view details</p>
                </div>
            )}
          </div>
        </div>
      </div>
  );
};

export default UserManagementPage;
