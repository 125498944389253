// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import Logout from './components/logout'; // Import Login Page
import DashboardPage from './components/DashboardPage'; // Import Dashboard Page
import AnimalManagementPage from './components/AnimalManagement';
import SettingPage from './components/SettingsPage';
import { ToastContainer } from 'react-toastify';
import './App.css';
import UserManagementPage from './components/UserManagementPage'; // Import the User Management Page
import { useAuth } from './components/AuthContext';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/user-management" element={<UserManagementPage />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/animal-management" element={<AnimalManagementPage />} />
          <Route path="/settings" element={<SettingPage />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        pauseOnFocusLoss
      />
    </Router>
  );
}

export default App;
