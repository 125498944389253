import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar'; // Import Sidebar component
import Modal from 'react-modal'; // Import react-modal for popup
import '../styles/SettingsPage.css'; // Import CSS for SettingsPage
import { collection, getDocs, query, where, addDoc, updateDoc, doc, getDoc } from 'firebase/firestore'; // Firebase Firestore
import { db } from '../firebase'; // Firebase config

Modal.setAppElement('#root'); // Set the root for accessibility

const SettingsPage = () => {
    const [admins, setAdmins] = useState([]); // State to store admin list
    const [isAdminModalOpen, setIsAdminModalOpen] = useState(false); // Modal state for adding admin
    const [isMaintenanceModalOpen, setIsMaintenanceModalOpen] = useState(false); // Modal state for maintenance mode
    const [isEnablingMaintenance, setIsEnablingMaintenance] = useState(false); // Loading state for Maintenance Mode
    const [maintenanceMode, setMaintenanceMode] = useState(false); // State to store the current maintenance mode status
    const [newAdmin, setNewAdmin] = useState({ name: '', email: '', admin: true }); // State for new admin details
    const [isAdding, setIsAdding] = useState(false); // Loading state
    const [activeTab, setActiveTab] = useState('admin'); // Manage active tab

    // Fetch admins from the users collection where admin is true
    const fetchAdmins = async () => {
        const usersCollection = collection(db, 'users');
        const adminsQuery = query(usersCollection, where('admin', '==', true));
        const adminSnapshot = await getDocs(adminsQuery);
        const adminList = adminSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setAdmins(adminList); // Store the fetched admins
    };

    useEffect(() => {
        fetchAdmins(); // Fetch admins on component mount
    }, []);

    // Fetch the current status of maintenanceMode when the component is mounted
    useEffect(() => {
        const fetchMaintenanceStatus = async () => {
            try {
                const settingsDoc = doc(db, 'settings', 'System-Admin'); // Document path
                const docSnapshot = await getDoc(settingsDoc);
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    setMaintenanceMode(data.maintenanceMode); // Update state with current maintenance mode status
                }
            } catch (error) {
                console.error("Error fetching maintenance mode status:", error);
            }
        };
        fetchMaintenanceStatus();
    }, []);

    // Tab change handler
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    // Open modal to add a new admin
    const openAdminModal = () => {
        setIsAdminModalOpen(true);
    };

    // Close the admin modal
    const closeAdminModal = () => {
        setNewAdmin({ name: '', email: '', admin: true }); // Reset form fields
        setIsAdminModalOpen(false);
    };

    // Open maintenance mode confirmation modal
    const openMaintenanceModal = () => {
        setIsMaintenanceModalOpen(true);
    };

    // Close maintenance mode confirmation modal
    const closeMaintenanceModal = () => {
        setIsMaintenanceModalOpen(false);
    };

    // Enable Maintenance Mode
    const handleEnableMaintenance = async () => {
        setIsEnablingMaintenance(true); // Start loading
        try {
            const settingsDoc = doc(db, 'settings', 'System-Admin'); // Use 'System-Admin' document
            await updateDoc(settingsDoc, { maintenanceMode: true });

            setMaintenanceMode(true); // Update the local state
            alert('Maintenance mode enabled.');
        } catch (error) {
            console.error('Error enabling maintenance mode:', error);
        } finally {
            setIsEnablingMaintenance(false); // Stop loading
            closeMaintenanceModal();
        }
    };

    // Disable Maintenance Mode
    const handleDisableMaintenance = async () => {
        setIsEnablingMaintenance(true); // Start loading
        try {
            const settingsDoc = doc(db, 'settings', 'System-Admin'); // Use 'System-Admin' document
            await updateDoc(settingsDoc, { maintenanceMode: false });

            setMaintenanceMode(false); // Update the local state
            alert('Maintenance mode disabled.');
        } catch (error) {
            console.error('Error disabling maintenance mode:', error);
        } finally {
            setIsEnablingMaintenance(false); // Stop loading
        }
    };

    // Handle adding an admin
    const handleAddAdmin = async () => {
        setIsAdding(true); // Start loading
        try {
            const usersCollection = collection(db, 'users');
            const emailQuery = query(usersCollection, where('email', '==', newAdmin.email));
            const emailSnapshot = await getDocs(emailQuery);

            if (!emailSnapshot.empty) {
                // User exists, just update the admin flag
                const existingUser = emailSnapshot.docs[0];
                const userDoc = doc(db, 'users', existingUser.id);
                await updateDoc(userDoc, { admin: true });

                alert(`User with email ${newAdmin.email} is now an admin.`);
            } else {
                // User doesn't exist, create new user with default password and admin flag
                const newUser = {
                    ...newAdmin,
                    password: 'ckgoat2024', // Default password
                };
                await addDoc(usersCollection, newUser);
                alert(`New admin created for ${newAdmin.email} with default password.`);
            }

            // Update the state to reflect the newly added admin
            setAdmins([...admins, { ...newAdmin }]);
            closeAdminModal(); // Close modal after adding
        } catch (error) {
            console.error('Error adding admin:', error);
        } finally {
            setIsAdding(false); // Stop loading
        }
    };

    // Delete admin function (remove admin flag or delete user)
    const handleDeleteAdmin = async (adminId) => {
        try {
            const userDoc = doc(db, 'users', adminId);
            await updateDoc(userDoc, { admin: false }); // Remove admin flag

            // Update the admin list in the UI
            setAdmins(admins.filter((admin) => admin.id !== adminId));
            alert('Admin deleted successfully.');
        } catch (error) {
            console.error('Error deleting admin:', error);
        }
    };

    return (
        <div className="settings-dashboard-container">
            <Sidebar activePage="settings" />

            <div className="settings-content">
                <header className="settings-header">
                    <h1>Settings</h1>
                </header>

                {/* Tab Navigation */}
                <div className="tabs-container">
                    <button
                        className={`tab-button ${activeTab === 'admin' ? 'active' : ''}`}
                        onClick={() => handleTabChange('admin')}
                    >
                        Admin Management
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'maintenance' ? 'active' : ''}`}
                        onClick={() => handleTabChange('maintenance')}
                    >
                        Maintenance Mode
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'storage' ? 'active' : ''}`}
                        onClick={() => handleTabChange('storage')}
                    >
                        Storage Optimization
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'otp' ? 'active' : ''}`}
                        onClick={() => handleTabChange('otp')}
                    >
                        OTP Dashboard
                    </button>
                </div>

                {/* Content Section based on active tab */}
                {activeTab === 'admin' && (
                    <div className="settings-section">
                        <h2>Admin Users</h2>
                        <div className="settings-item">
                            <button onClick={openAdminModal} className="settings-button">
                                Add New Admin
                            </button>
                        </div>
                        {/* List of Admins */}
                        <div className="admin-list">
                            <h3>Existing Admins</h3>
                            <ul> {admins.length > 0 ? (
                                admins.map((admin) => (
                                    <div className="admin-list-item" key={admin.id}>
                                        <h2>{admin.name}</h2>
                                        <p>{admin.email}</p>
                                        <button key={admin.id}
                                            onClick={() => handleDeleteAdmin(admin.id)}
                                            className="delete-user-btn"
                                        >
                                            <i className="fas fa-trash"></i> {/* Delete Icon */}
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <p>No admins found</p>
                            )}
                            </ul>
                        </div>
                    </div>
                )}

                {activeTab === 'maintenance' && (
                    <div className="settings-section">
                        <h2>Maintenance Mode</h2>
                        <p>Current Status: {maintenanceMode ? "Enabled" : "Disabled"}</p>
                        {maintenanceMode ? (
                            <button
                                className="settings-button"
                                onClick={handleDisableMaintenance}
                                disabled={isEnablingMaintenance}
                            >
                                {isEnablingMaintenance ? 'Disabling...' : 'Disable Maintenance Mode'}
                            </button>
                        ) : (
                            <button
                                className="settings-button"
                                onClick={openMaintenanceModal}
                                disabled={isEnablingMaintenance}
                            >
                                {isEnablingMaintenance ? 'Enabling...' : 'Enable Maintenance Mode'}
                            </button>
                        )}
                    </div>
                )}

                {activeTab === 'storage' && (
                    <div className="settings-section">
                        <h2>Storage Optimization</h2>
                        <p>Optimize storage to free up space on the server.</p>
                        <button className="settings-button">Optimize Storage</button>
                    </div>
                )}

                {activeTab === 'otp' && (
                    <div className="settings-section">
                        <h2>OTP Dashboard</h2>
                        <p>View and manage OTP activity.</p>
                        <button className="settings-button" onClick={() => window.open('https://sms.greatshark.in')}>
                            Go to OTP Dashboard
                        </button>
                    </div>
                )}

                {/* Modal for Adding Admin */}
                <Modal
                    isOpen={isAdminModalOpen}
                    onRequestClose={closeAdminModal}
                    contentLabel="Add Admin User"
                    className="admin-modal"
                    overlayClassName="admin-modal-overlay"
                >
                    <h2>Add New Admin</h2>
                    <div className="modal-content">
                        <label>Name:</label>
                        <input
                            type="text"
                            placeholder="Enter admin name"
                            value={newAdmin.name}
                            onChange={(e) => setNewAdmin({ ...newAdmin, name: e.target.value })}
                        />
                        <label>Email:</label>
                        <input
                            type="email"
                            placeholder="Enter admin email"
                            value={newAdmin.email}
                            onChange={(e) => setNewAdmin({ ...newAdmin, email: e.target.value })}
                        />
                        <div className="modal-actions">
                            <button onClick={handleAddAdmin} className="confirm-add-btn" disabled={isAdding}>
                                {isAdding ? 'Adding...' : 'Add Admin'}
                            </button>
                            <button onClick={closeAdminModal} className="cancel-add-btn" disabled={isAdding}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal>

                {/* Maintenance Mode Confirmation Modal */}
                <Modal
                    isOpen={isMaintenanceModalOpen}
                    onRequestClose={closeMaintenanceModal}
                    contentLabel="Enable Maintenance Mode"
                    className="maintenance-modal"
                    overlayClassName="maintenance-modal-overlay"
                >
                    <h2>Are you sure you want to enable maintenance mode?</h2>
                    <div className="modal-actions">
                        <button onClick={handleEnableMaintenance} className="confirm-btn">
                            Yes, Enable
                        </button>
                        <button onClick={closeMaintenanceModal} className="cancel-btn">
                            Cancel
                        </button>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default SettingsPage;
