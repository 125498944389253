import {
    initializeApp
} from "firebase/app";
import {
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    setPersistence,
    browserLocalPersistence,
    signInWithEmailAndPassword
} from "firebase/auth";
import {
    getFirestore,
    doc,
    getDoc
} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyACT0v0qg-08JnYaEoFxiS0laTzZ9rnN0c",
    authDomain: "ck-goatfarm.firebaseapp.com",
    projectId: "ck-goatfarm",
    storageBucket: "ck-goatfarm.appspot.com",
    messagingSenderId: "844509597602",
    appId: "1:844509597602:web:0468853a5076793d8eecfb",
    measurementId: "G-SG8H4EZP94"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// Sign in with Google function
const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;

        // Check if user is an admin
        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists() && docSnap.data().admin === true) {
            await setPersistence(auth, browserLocalPersistence); // Persist session across browser restarts
            return true;  // User is admin
        } else {
            return false; // User is not an admin
        }
    } catch (error) {
        console.error("Error during Google sign-in:", error);
        throw error;
    }
};

// Sign in with email and password function
const signInWithEmail = async (email, password) => {
    try {
        const result = await signInWithEmailAndPassword(auth, email, password);
        const user = result.user;

        // Check if user is an admin
        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists() && docSnap.data().admin === true) {
            await setPersistence(auth, browserLocalPersistence); // Persist session across browser restarts
            return true;  // User is admin
        } else {
            return false; // User is not an admin
        }
    } catch (error) {
        console.error("Error during email sign-in:", error);
        throw error;
    }
};

// Export the Firebase configurations and functions
// Initialize Firestore
const db = getFirestore(app);
export { app, auth, db, signInWithGoogle, signInWithEmail };
