// src/components/Sidebar.js
import React from 'react';
import '../styles/Sidebar.css';

const Sidebar = ({ activePage }) => {
  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <h2>Admin Panel</h2>
      </div>
      <ul className="sidebar-links">
        <li>
          <a href="/dashboard" className={activePage === 'dashboard' ? 'active' : ''}>
            Dashboard
          </a>
        </li>
        <li>
          <a href="/user-management" className={activePage === 'user-management' ? 'active' : ''}>
            User Management
          </a>
        </li>
        <li>
          <a href="/animal-management" className={activePage === 'animal-management' ? 'active' : ''}>
            Animal Posts
          </a>
        </li>
        <li>
          <a href="/settings" className={activePage === 'settings' ? 'active' : ''}>
            Settings
          </a>
        </li>
        <li>
          <a href="/logout" className={activePage === 'logout' ? 'active' : ''}>
            Logout
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
