import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle, signInWithEmail } from '../firebase'; // Import Firebase functions
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/logo.png'; // Logo image
import '../styles/LoginPage.css'; // Import the CSS file for LoginPage

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Function to check if the user is already logged in
  useEffect(() => {
    const user = localStorage.getItem('user'); // This assumes you store user data in localStorage
    if (user) {
      // If the user is logged in, redirect them to the dashboard
      navigate('/dashboard');
    }
  }, [navigate]);

  // Function to handle login via email and password
  const handleLogin = async (e) => {
    e.preventDefault();

    if (email && password) {
      try {
        const isAdmin = await signInWithEmail(email, password); // Authenticate with Firebase
        if (isAdmin) {
          localStorage.setItem('user', email); // Store user info (or token) in localStorage
          toast.success("Login Successful!");
          setTimeout(() => {
            navigate('/dashboard'); // Redirect to dashboard after login
          }, 1000);
        } else {
          toast.error("You are not authorized as an Admin.");
        }
      } catch (error) {
        toast.error("Login failed. Please check your email and password.");
      }
    } else {
      toast.error("Please enter both email and password!");
    }
  };

  // Function to handle login with Google
  const handleGoogleSignIn = async () => {
    try {
      const isAdmin = await signInWithGoogle(); // Assume this returns a boolean for admin status
      if (isAdmin) {
        localStorage.setItem('user', 'admin'); // Store user data (or token)
        toast.success("Welcome Admin!");
        setTimeout(() => {
          navigate('/dashboard'); // Redirect to dashboard
        }, 1000);
      } else {
        toast.error("You are not authorized as an Admin.");
      }
    } catch (error) {
      toast.error("Error during login.");
    }
  };

  return (
      <div className="login-container">
        <div className="login-box">
          <img src={logo} alt="Company Logo" className="company-logo" />
          <h2>Admin Login</h2>
          <form onSubmit={handleLogin}>
            <input
                type="text"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit">Login</button>
          </form>
          <div className="google-signin">
            <button type="button" onClick={handleGoogleSignIn}>
              Sign in with Google
            </button>
          </div>
          <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              closeOnClick
              pauseOnHover
              draggable
              pauseOnFocusLoss
          />
        </div>
      </div>
  );
};

export default LoginPage;
