import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar'; // Import the Sidebar component
import '../styles/DashboardPage.css'; // Import the CSS file for dashboard styling
import { collection, getDocs, doc, getDoc, query, where } from 'firebase/firestore'; // Firebase Firestore
import { db } from '../firebase'; // Firebase configuration
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'; // Import Circular Progressbar
import 'react-circular-progressbar/dist/styles.css'; // Import Circular Progressbar styles

const DashboardPage = () => {
  const [totalUsers, setTotalUsers] = useState(0); // State for total users
  const [totalAnimalPosts, setTotalAnimalPosts] = useState(0); // State for total animal posts
  const [premiumUsers, setPremiumUsers] = useState(0); // State for total premium users
  const [totalStorage] = useState(100); // Total storage (100 GB)
  const [usedStorage, setUsedStorage] = useState(1); // Placeholder for remaining storage (in GB)
  const [maintenanceMode, setMaintenanceMode] = useState(false); // State for maintenance mode

  // Fetch total number of users
  const fetchTotalUsers = async () => {
    const usersCollection = collection(db, 'users');
    const userSnapshot = await getDocs(usersCollection);
    setTotalUsers(userSnapshot.size); // Set the total users from the snapshot size
  };

  // Fetch total number of premium users
  const fetchPremiumUsers = async () => {
    const usersCollection = collection(db, 'users');
    const premiumUsersQuery = query(usersCollection, where('premium', '==', true));
    const premiumUserSnapshot = await getDocs(premiumUsersQuery);
    setPremiumUsers(premiumUserSnapshot.size); // Set the total premium users from the query snapshot size
  };

  // Fetch total number of animal posts
  const fetchTotalAnimalPosts = async () => {
    const animalsCollection = collection(db, 'animals');
    const animalSnapshot = await getDocs(animalsCollection);
    setTotalAnimalPosts(animalSnapshot.size); // Set the total animal posts
  };

  // Fetch current maintenance mode status
  const fetchMaintenanceStatus = async () => {
    const settingsDoc = doc(db, 'settings', 'System-Admin');
    const settingsSnapshot = await getDoc(settingsDoc);
    if (settingsSnapshot.exists()) {
      setMaintenanceMode(settingsSnapshot.data().maintenanceMode); // Set the maintenance mode status
    }
  };

  // Calculate the percentage of used storage
  const usedStoragePercentage = ((totalStorage - usedStorage) / totalStorage) * 100;

  // Fetch all data when the component mounts
  useEffect(() => {
    fetchTotalUsers();
    fetchPremiumUsers();
    fetchTotalAnimalPosts();
    fetchMaintenanceStatus();
    // Mock storage data, replace with actual API call to fetch used storage if available
    setUsedStorage(99); // Mock used storage value (99 GB remaining)
  }, []);

  return (
      <div className="dashboard-container">
        {/* Left Sidebar */}
        <Sidebar activePage="dashboard" />

        {/* Right Content Area */}
        <div className="dashboard-content">
          <header className="dashboard-header">
            <h1>Admin Dashboard</h1>
          </header>

          {/* Dashboard Widgets */}
          <div className="dashboard-widgets">
            {/* Total Users Widget */}
            <div className={`widget ${maintenanceMode ? 'maintenance-enabled' : 'maintenance-disabled'}`}>
              <h3>Maintenance Mode</h3>
              <p>{maintenanceMode ? 'Enabled' : 'Disabled'}</p>
            </div>


            {/* Total Premium Users Widget */}
            <div className="widget">
              <h3>Total Premium Users</h3>
              <p>{premiumUsers}</p>
            </div>

            {/* Total Animal Posts Widget */}
            <div className="widget">
              <h3>Total Animal Posts</h3>
              <p>{totalAnimalPosts}</p>
            </div>

            {/* Remaining Storage Widget with Radial Gauge */}
            <div className="widget radial-widget">
              <h3>Remaining Storage</h3>
              <div className="radial-gauge">
                <CircularProgressbar
                    value={usedStoragePercentage}
                    text={`${usedStoragePercentage.toFixed(0)}% Used`}
                    styles={buildStyles({
                      textColor: '#1976d2',
                      pathColor: '#1976d2',
                      trailColor: '#f4f4f9',
                    })}
                />
              </div>
              <p>{usedStorage}GB / 100GB remaining</p>
            </div>
            <div className="widget">
              <h3>Total Users</h3>
              <p>{totalUsers}</p>
            </div>

            {/* Maintenance Mode Status Widget */}

          </div>
        </div>
      </div>
  );
};

export default DashboardPage;
