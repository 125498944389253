import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {signOut} from "firebase/auth";
import {auth} from "../firebase";

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const logout = async () => {
            try {
                await signOut(auth);
                localStorage.clear();// Firebase sign-out
                console.log("User has been logged out.");
                navigate('/'); // Redirect to the login page
            } catch (error) {
                console.error("Error logging out:", error);
            }
        };

        logout();
    }, [navigate]); // Keep navigate in the dependency array

    return null; // No UI for the logout component
};

export default Logout;
