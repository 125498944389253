import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc, query, orderBy} from 'firebase/firestore';
import { db } from '../firebase'; // Firestore configuration
import Sidebar from './Sidebar'; // Import Sidebar component
import '../styles/AnimalManagementPage.css'; // Import separate CSS for Animal Management
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome for icons
import Modal from 'react-modal'; // Import react-modal for popup functionality

Modal.setAppElement('#root'); // Set app element for accessibility

const AnimalManagementPage = () => {
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [animals, setAnimals] = useState([]); // State for all animals
    const [filteredAnimals, setFilteredAnimals] = useState([]); // State for filtered animals
    const [animalToDelete, setAnimalToDelete] = useState(null); // State to hold the animal to delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // Modal state for delete confirmation

    // Fetch animal ads from Firestore
    const fetchAnimals = async () => {
        try {
            // Create a query to fetch animals sorted by 'createdAt' field in descending order
            const animalCollection = collection(db, 'animals');
            const animalQuery = query(animalCollection, orderBy('timestamp', 'desc')); // Order by timestamp field

            const animalSnapshot = await getDocs(animalQuery); // Fetch documents with sorting
            const animalList = animalSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            setAnimals(animalList);
            setFilteredAnimals(animalList); // Initialize filtered animals with all animals
        } catch (error) {
            console.error('Error fetching animal ads:', error);
        }
    };

    useEffect(() => {
        fetchAnimals();
    }, []);

    // Filter animals based on mobile number search query
    useEffect(() => {
        const filtered = animals.filter((animal) =>
            animal.mobileNumber.includes(searchQuery)
        );
        setFilteredAnimals(filtered);
    }, [searchQuery, animals]);

    // Handle Delete Animal functionality
    const handleDeleteAnimal = async () => {
        if (animalToDelete) {
            try {
                const animalRef = doc(db, 'animals', animalToDelete.id);
                await deleteDoc(animalRef); // Delete the animal document
                setFilteredAnimals((prevAds) => prevAds.filter((ad) => ad.id !== animalToDelete.id)); // Remove from UI
                closeDeleteModal(); // Close the confirmation modal
            } catch (error) {
                console.error('Error deleting animal ad:', error);
            }
        }
    };

    // Open delete confirmation modal
    const openDeleteModal = (animal) => {
        setAnimalToDelete(animal);
        setIsDeleteModalOpen(true);
    };

    // Close delete confirmation modal
    const closeDeleteModal = () => {
        setAnimalToDelete(null);
        setIsDeleteModalOpen(false);
    };

    return (
        <div className="animal-dashboard-container">
            <Sidebar activePage="animal-management" />

            <div className="animal-management-content">
                {/* Header and Search at the top */}
                <div className="animal-section">
                    <header className="animal-management-header">
                        <h1>Animal Management</h1>
                        <input
                            type="text"
                            placeholder="Search by mobile number..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="animal-search-input"
                        />
                    </header>

                    {/* Animal posts in 3-column grid */}
                    <div className="animal-ads-grid">
                        {filteredAnimals.length > 0 ? (
                            filteredAnimals.map((animal) => (
                                <div key={animal.id} className="animal-ad-card">

                                    {animal.thumbnail ? (
                                        <img src={animal.thumbnail} alt={animal.animalType}
                                             className="animal-thumbnail"/>
                                    ) : (
                                        <div className="no-thumbnail">No Image Available</div>
                                    )}
                                    <div className="animal-info">
                                        <h4>{animal.animalType} {animal.breed}</h4>
                                        <p>Price: {animal.price}</p>
                                        <p>Address: {animal.address}</p>
                                        <p>City: {animal.city}</p>
                                        <p>Mobile No.: {animal.mobileNumber}</p>
                                    </div>
                                    <button onClick={() => openDeleteModal(animal)} className="animal-delete-icon-btn">
                                        <i className="fas fa-trash"></i> Delete {/* FontAwesome trash icon */}
                                    </button>
                                </div>
                            ))
                        ) : (
                            <p>No animal ads found</p>
                        )}
                    </div>
                </div>

                {/* Delete Confirmation Modal */}
                <Modal
                    isOpen={isDeleteModalOpen}
                    onRequestClose={closeDeleteModal}
                    contentLabel="Confirm Delete Post"
                    className="animal-delete-modal"
                    overlayClassName="animal-delete-modal-overlay"
                >
                    <h2>Are you sure you want to delete this animal ad?</h2>
                    <div className="modal-actions">
                        <button onClick={handleDeleteAnimal} className="confirm-delete-btn">Confirm</button>
                        <button onClick={closeDeleteModal} className="cancel-delete-btn">Cancel</button>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default AnimalManagementPage;
